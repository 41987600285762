import React from "react";
import Header from "./Header";
import ErrorBoundary from "../Auth/ErrorBoundary";

export const PageLayout = (props) => {

  return (
    <>
      <Header />
      <ErrorBoundary>
      <div className="content">
        {props.children}
      </div>
      </ErrorBoundary>
    </>
  );
};