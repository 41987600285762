import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";
import InputGroup from "react-bootstrap/InputGroup";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";
import Select from "react-select";
import AddPhonePopup from "./AddPhonePopup";
import AddEmailPopup from "./AddEmailPopup";
import useFetch from "../../../Utilities/useFetch";
import FuncFetch from "../../../Utilities/funcFetch";
import { useMsal } from "@azure/msal-react";

function Contact({ 
  phones, 
  setPhones, 
  emails, 
  setEmails, 
  debtorID ,
  className,
  updateAccountFieldsAndActivities
}) {
  const [selectedPhone, setSelectedPhone] = useState();
  const [phoneOptions, setPhoneOptions] = useState();
  const [emailOptions, setEmailOptions] = useState();
  const [openAddPhonePopup, setOpenAddPhonePopup] = useState(false);
  const [phoneParams, setPhoneParams] = useState();
  const { instance, accounts } = useMsal();
  const [selectedEmail, setSelectedEmail] = useState();
  const [openAddEmailPopup, setOpenAddEmailPopup] = useState(false);
  const [emailParams, setEmailParams] = useState();

  const [placeholderPostObj] = useState({});
  const { REACT_APP_API_URL } = process.env;

  useEffect(()=>{
    // If debtorID changes and there are no phones/emails associated, 
    // this ensures that the field shows as blank field and not the last selected phone/email
    if(debtorID){
      handleDropdownOpen()
      setSelectedPhone(null)
      setSelectedEmail(null)
    }
  // eslint-disable-next-line
  },[debtorID])

  const {
    loading: phoneLoading,
    status: phoneStatus,
    error: phoneError,
    setStatus: setPhoneStatus,
    setError: setPhoneError,
  } = useFetch(phoneParams,`${REACT_APP_API_URL}/Claims/PutPhoneNDR`,"PUT", placeholderPostObj, phoneParams);

  const {
    loading: emailLoading,
    status: emailStatus,
    error: emailError,
    setStatus: setEmailStatus,
    setError: setEmailError,
  } = useFetch(emailParams,`${REACT_APP_API_URL}/Claims/PutEmailNDR`,"PUT",placeholderPostObj,emailParams);

  useEffect(() => {
    if (phones && phones.length > 0) {
      let formattedPhoneOptions = phones.map((phone) => {
        let formattedPhoneNumber = formatPhoneNumber(phone.phoneNumber)
        if (phone.verified){
          // this adds a checkmark to the beginning of the phone number if it is verified
          formattedPhoneNumber = "\u2713"+formattedPhoneNumber
        }
        if(phone.ndr){
          return {
            value: phone.phoneNumber,
            label: formattedPhoneNumber + "(NDR)",
          };
        }else if (phone.isRO && !phone.isClaimant) {
          return {
            value: phone.phoneNumber,
            label: formattedPhoneNumber + "(RO)",
          };
        } else if (phone.isClaimant && !phone.isRO) {
          return {
            value: phone.phoneNumber,
            label: formattedPhoneNumber + "(CL)",
          };
        } else if (phone.isClaimant && phone.isRO) {
          return {
            value: phone.phoneNumber,
            label: formattedPhoneNumber + "(RO,CL)",
          };
        } else {
          return { 
            value: phone.phoneNumber, 
            label: formattedPhoneNumber 
          };
        }
      });
      formattedPhoneOptions = sortByVerification(formattedPhoneOptions);
      setSelectedPhone(formattedPhoneOptions[0]);
      setPhoneOptions(formattedPhoneOptions);
    }
  }, [phones]);

  const sortByVerification = (arr) => {
    return arr.sort((a, b) => {
      a = a.label;
      b = b.label;
      if (a.startsWith("\u2713") && !b.startsWith("\u2713")) return -1;
      if (!a.startsWith("\u2713") && b.startsWith("\u2713")) return 1;
      if (a.includes('NDR') && !b.includes('NDR')) return 1;
      if (!a.includes('NDR') && b.includes('NDR')) return -1;
      return a.localeCompare(b);
    });
  };

  useEffect(() => {
    if (emails && emails.length > 0) {
      let formattedEmailOptions = emails.map((email) => {
        if(email.ndr){
          return {
            value: email.emailAddress,
            label: email.emailAddress + "(NDR)",
          };
        }else if (email.accountHolder === 2) {
          return {
            value: email.emailAddress,
            label: email.emailAddress + "(RO)",
          };
        } else if (email.accountHolder === 1) {
          return {
            value: email.emailAddress,
            label: email.emailAddress + "(CL)",
          };
        } else if (email.accountHolder === 3) {
          return {
            value: email.emailAddress,
            label: email.emailAddress + "(Other)",
          };
        } else {
          return { 
            value: email.emailAddress, 
            label: email.emailAddress 
          };
        }
      });
      formattedEmailOptions = sortByVerification(formattedEmailOptions);
      setSelectedEmail(formattedEmailOptions[0]);
      setEmailOptions(formattedEmailOptions);
    }
  }, [emails]);

  const openInNewTab = (url, linkType) => {
    if (linkType === "tel") {
      url = url.replace(/\D+/g, "");
      url = "tel:" + url;
    }
    const newWindow = window.open(url, "_blank", "noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const handleEmailNDR = () => {
    setEmailParams({
      emailaddress: selectedEmail.value,
      debtorid: debtorID,
    });
  };

  const handlePhoneNDR = () => {
    setPhoneParams({
      phonenumber: selectedPhone.value,
      debtorid: debtorID,
    });
  };

  function formatPhoneNumber(phoneNumberString) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return null;
  }

  const handleCopyToClipboard = (value) => { 
    navigator.clipboard.writeText(value);
   }

   const handleCopyToClipboardAndRecordCall = (value) => { 
    navigator.clipboard.writeText(value);
    onCallHandler(true)
   }

  const handleDropdownOpen = (isOpen) => {
    if (isOpen) {
      setPhoneError(null);
      setPhoneStatus(null);
      setEmailError(null);
      setEmailStatus(null);
    }
  };

  const onCallHandler = (bypassPopup) => {
    FuncFetch(
      `${REACT_APP_API_URL}/Claims/RecordCall`,
      {debtorID,phonenumber:selectedPhone.value},
      'POST',
      {},
      instance,
      accounts
    )
    .then((activitiesFromPhone) => {
      updateAccountFieldsAndActivities(activitiesFromPhone)
      if(!bypassPopup){
        openInNewTab(`tel:${selectedPhone.value}`, "tel")
      }
    })
    .catch((err) => console.error('There was an issue, please try again.'))
  };

  const onEmailHandler = () => {
    FuncFetch(
      `${REACT_APP_API_URL}/Claims/RecordEmail`,
      {debtorID,email:selectedEmail.value},
      'POST',
      {},
      instance,
      accounts
    )
    .then((activitiesFromEmail) => {
      updateAccountFieldsAndActivities(activitiesFromEmail)
      openInNewTab(`mailto:${selectedEmail.value}`)
    })
    .catch((err) => console.error('There was an issue, please try again.'))
  };

  return (
    <>
      {openAddPhonePopup && (
        <AddPhonePopup
          show={openAddPhonePopup}
          setShow={setOpenAddPhonePopup}
          debtorID={debtorID}
          setPhones={setPhones}
        />
      )}
      {openAddEmailPopup && (
        <AddEmailPopup
          show={openAddEmailPopup}
          setShow={setOpenAddEmailPopup}
          debtorID={debtorID}
          setEmails={setEmails}
        />
      )}
        <Row className={`contact-wrapper ${className?className:''}`}>
          <Col className="full-width">
          <InputGroup className="no-wrap">
            <Select
              options={phoneOptions}
              value={selectedPhone}
              onChange={(e) => setSelectedPhone(e)}
              placeholder="Select a phone number..."
            />
            <Dropdown
              as={ButtonGroup}
              className="width-20"
              onToggle={(e) => handleDropdownOpen(e)}
            >
              <Button
                disabled={!selectedPhone}
                variant="secondary"
                onClick={() => onCallHandler()}
              >
                Call
              </Button>

              <Dropdown.Toggle
                split
                variant="secondary"
                id="dropdown-split-basic"
              />

              <Dropdown.Menu>
                {selectedPhone && (
                  <Dropdown.Item
                    onClick={() => handlePhoneNDR(true)}
                    disabled={phoneError}
                  >
                    Mark as NDR
                  </Dropdown.Item>
                )}
                <Dropdown.Item onClick={() => setOpenAddPhonePopup(true)}>
                  Add Phone
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleCopyToClipboardAndRecordCall(formatPhoneNumber(selectedPhone.value))}>
                  Copy and Call
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleCopyToClipboard(formatPhoneNumber(selectedPhone.value))}>
                  Copy to Clipboard
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </InputGroup>
          <APIStatus
              loading={phoneLoading}
              status={phoneStatus}
              error={phoneError}
              className="full-width"
              successMessage="Successfully marked as NDR"
              errorMessage="There was an issue, please try again"
            />
          </Col>
          <Col>
          <InputGroup className="no-wrap">
            <Select
              options={emailOptions}
              value={selectedEmail}
              onChange={(e) => setSelectedEmail(e)}
              placeholder="Select an email..."
            />
            <Dropdown
              as={ButtonGroup}
              className="width-20"
              onToggle={(e) => handleDropdownOpen(e)}
            >
              <Button
                disabled={!selectedEmail}
                variant="secondary"
                onClick={() => onEmailHandler()}
              >
                Email
              </Button>

              <Dropdown.Toggle
                split
                variant="secondary"
                id="dropdown-split-basic"
              />

              <Dropdown.Menu>
                {selectedEmail && (
                  <Dropdown.Item
                    onClick={() => handleEmailNDR(true)}
                    disabled={emailError}
                  >
                    Mark as NDR
                  </Dropdown.Item>
                )}
                <Dropdown.Item onClick={() => setOpenAddEmailPopup(true)}>
                  Add Email
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleCopyToClipboard(selectedEmail.value)}>
                  Copy to Clipboard
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </InputGroup>
          <APIStatus
              loading={emailLoading}
              status={emailStatus}
              error={emailError}
              className="full-width"
              successMessage="Successfully marked as NDR"
              errorMessage="There was an issue, please try again"
            />
          </Col>
        </Row>
    </>
  );
}

function APIStatus({
  loading,
  status,
  error,
  className,
  successMessage,
  errorMessage,
}) {
  return loading ? (
    <Spinner animation="border" size="sm" />
  ) : status && !error ? (
    <Alert className={className}>{successMessage}</Alert>
  ) : (
    error && (
      <Alert variant="warning" className={className}>
        {errorMessage}
      </Alert>
    )
  );
}

export default Contact;
