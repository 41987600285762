import React, {useState} from 'react'
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import FuncFetch from '../Utilities/funcFetch';
import { useMsal } from '@azure/msal-react';


function APIUpdatingCheckbox({
    readOnly,
    checked,
    debtorID,
    updateAccountFieldsAndActivities,
    APIUrl,
    fieldName
}) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const { REACT_APP_API_URL } = process.env;
    const { accounts, instance } = useMsal();

    const handleChange = (e) => { 
        setLoading(true)
        FuncFetch(`${REACT_APP_API_URL}${APIUrl}`,{debtorID},'POST',null,instance,accounts)
        .then(response=>{
            setLoading(false)
            updateAccountFieldsAndActivities({activities:response,[fieldName]:!checked})
        })
        .catch(error=>{
            setLoading(false)
            console.error(error)
            setError('There was an issue, please refresh and try again')
        })
     }

  return( 
    <>
    {loading?
        <Spinner animation='border' />
    :error?
        <Alert variant='warning'>{error}</Alert>
    :
        <Form.Check readOnly={readOnly} checked={checked} onClick={(e)=>handleChange(e)} />
    }
    </>
)
  
}

export default APIUpdatingCheckbox