import React, { useEffect, useRef, useState } from 'react'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'
import InputGroup from 'react-bootstrap/InputGroup'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import FuncFetch from '../../Utilities/funcFetch'
import { useMsal } from '@azure/msal-react'
import { AgGridReact } from 'ag-grid-react'
import { formatDate } from '../../Utilities/GridTools'
import './SearchForm.css'
import { Spinner } from 'react-bootstrap'
import CollectorClaimModal from '../NCD/CollectorClaimModal'
import { AdvancedSearch } from './AdvancedSearch'

function SearchForm() {
    const [searchParam, setSearchParam] = useState('')
    const [searchLoading, setSearchLoading] = useState(false);
    const [selectedClaim, setSelectedClaim] = useState();
    const [showClaimModal, setShowClaimModal] = useState(false);
    const { instance, accounts } = useMsal();    
    const { REACT_APP_API_URL } = process.env;
    const [defaultColDef] = useState({
        sortable: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        width: 50,
      })
    const [columnDefs] = useState([
        {
            headerName: "Row",
            "field": "rowNumber"
        },
        {
            "field": "debtorId"
        },
        {
            "field": "clientName"
        },
        {
            "field": "debtorStatus"
        },
        {
            "field": "acctStatus"
        },
        {
            "field": "projectId"
        },
        {
            "field": "claimNumber"
        },
        {
            "field": "adjuster"
        },
        {
            "field": "listDate",
            "valueFormatter": formatDate,
            filter: 'agDateColumnFilter'
        },
        {
            "field": "dol",
            headerName: 'DOL',
            "valueFormatter": formatDate,
            filter: 'agDateColumnFilter'
        },
        {
            "field": "age"
        },
        {
            "field": "statusCode"
        },
        {
            "field": "statusDate",
            "valueFormatter": formatDate,
            filter: 'agDateColumnFilter'
        },
        {
            "field": "lastPtmAmt"
        },
        {
            "field": "lastPtmDt",
            "valueFormatter": formatDate,
            filter: 'agDateColumnFilter'
        },
        {
            "field":"comment",
        }
      ])
    const [claims, setClaims] = useState([]);
    const [key, setKey] = useState();
    const inputRef = useRef(null);
    const buttonRef = useRef(null);
  
    useEffect(() => {
      const handleKeyPress = (event) => {
        if (event.key === "Enter") {
          buttonRef.current.click();
        }
      };
  
      const inputElement = inputRef.current;
      inputElement.addEventListener("keypress", handleKeyPress);
  
      // Cleanup event listener on component unmount
      return () => {
        inputElement.removeEventListener("keypress", handleKeyPress);
      };
    }, []);

    useEffect(()=>{
        if(key){
            setClaims(null)
            setSearchParam('')
        }
    },[key])

    const searchClaims = (e) => { 
        if(searchParam!==''){
            setSearchLoading(true)
            FuncFetch(`${REACT_APP_API_URL}/Claims/SearchClaims`,{search:searchParam},'POST',{},instance,accounts)
            .then(result=>{
                setSearchLoading(false)
                setClaims(result)
            })
            .catch(err=>{
                console.error('There was an issue, please try again.')
            })
        }
     }

    const onCellClicked = (e) => { 
        setSelectedClaim(e.data.debtorId);
        setShowClaimModal(true);
     }

  return (
    <>
    <CollectorClaimModal 
        show={showClaimModal} 
        setShow={setShowClaimModal} 
        selectedClaim={selectedClaim}
        setSelectedClaim={setSelectedClaim}
        claims={claims}
        />

    <h3>Claim Search</h3>
        <Tabs
            activeKey={key}
            onSelect={(k) => setKey(k)}
            >
            <Tab eventKey="basic" title="Basic">
                <Row>
                    <Col>
                        <InputGroup>
                            <Form.Control ref={inputRef} placeholder='Search...' value={searchParam} onChange={(e)=>setSearchParam(e.target.value)} />
                            <Button ref={buttonRef} onClick={()=>searchClaims()}>
                                {searchLoading?
                                <Spinner animation='border' size='sm' />
                                :'Search'}
                            </Button>
                        </InputGroup>
                    </Col>
                </Row>
            </Tab>
            <Tab eventKey="advanced" title="Advanced">
                <AdvancedSearch setClaims={setClaims} />
            </Tab>
        </Tabs>
    {/* I keep claims defaulted to [] instead of null because AgGrid will show 'loading...' if it is null
        also if there are no search results returned, the api returns [null] so I have to check for that */}
    {(claims&&claims.length>0&&claims[0]!==null)?
        <Alert>{claims.length} Claims Found</Alert>
    :null}

    <div className="ag-theme-alpine grid">
        <AgGridReact
            multiSortKey='ctrl'
            defaultColDef={defaultColDef}
            rowData={claims}
            columnDefs={columnDefs}
            onCellClicked={onCellClicked}
            onFirstDataRendered={(params) => params.columnApi.autoSizeAllColumns()}
        ></AgGridReact>
    </div>
    </>
  )
}



export default SearchForm