import React from 'react';
import { ProfileData } from './Components/ProfileData';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import ProtectedRoute from './Components/Auth/ProtectedRoute';
import Login from './Components/Auth/Login';
import NCD from './Components/Claims/NCD/NCD';
import SearchForm from './Components/Claims/Search/SearchForm';
// import SharePointIframe from './Components/SharepointIframe';

export default function App() {
  return (
    <Routes>
      <Route 
        exact 
        path='/' 
        element={<ProtectedRoute><SearchForm /></ProtectedRoute>} 
      />
      <Route 
        path='/openclaims' 
        element={<ProtectedRoute><SearchForm /></ProtectedRoute>} 
      />
      <Route 
        path='/claims/NCD' 
        element={<ProtectedRoute><NCD /></ProtectedRoute>} 
      />
      <Route 
        path='/claims/OpenClaims' 
        element={<ProtectedRoute><SearchForm /></ProtectedRoute>} 
      />
      <Route 
        path='/login' 
        element={<Login />} 
      />
      <Route 
        path='/profile' 
        element={<ProtectedRoute><ProfileData /></ProtectedRoute>} 
      />
    </Routes>
  );
}
