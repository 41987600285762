import React, { useEffect, useState } from 'react'
import useFetch from '../../Utilities/useFetch';
import ReactSelect from 'react-select';

function DropdownDebtorStatus({
    defaultStatus,
    onChange
}) {
    const { REACT_APP_API_URL } = process.env;
    const [selectedStatus, setSelectedStatus] = useState();
    const [debtorStatusOptions, setDebtorStatusOptions] = useState();
    const {data: debtorStatus} = useFetch(!debtorStatusOptions,`${REACT_APP_API_URL}/Claims/GetDebtorStatus`,'GET')

    const handleStatusChange = (e) => { 
        setSelectedStatus(e)
        onChange(e)
     }
    
    useEffect(()=>{
        if(debtorStatus){
            setDebtorStatusOptions(debtorStatus.map(status=>{
                let newOption = {value:status.key,label:status.value}
                if(defaultStatus&&status.value.toUpperCase()===defaultStatus.toUpperCase()){
                    setSelectedStatus(newOption)
                }
                return newOption
            }))
        }
    }, [debtorStatus, defaultStatus])

  return (

        <ReactSelect value={selectedStatus} onChange={handleStatusChange} options={debtorStatusOptions} />

  )
}

export default DropdownDebtorStatus