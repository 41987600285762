import React, { useEffect, useState } from 'react';
import FuncFetch from '../Utilities/funcFetch';
import { useMsal } from '@azure/msal-react';
import Form from 'react-bootstrap/Form';
import { Spinner } from 'react-bootstrap';
import { BsCheck, BsX } from 'react-icons/bs';

function NCDDropdown({
    defaultDate,
    disabled,
    onChange,
    debtorid,
    updateAccountFieldsAndActivities
}) {
    const {instance,accounts} = useMsal();
    const {REACT_APP_API_URL} = process.env;
    const [date, setDate] = useState();
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState();
    const [error, setError] = useState();

    useEffect(()=>{
        if(defaultDate){
            setDate(defaultDate.split('.')[0])
        }
    },[defaultDate])

    const handleBlur = (e) => { 
        setLoading(true);
        FuncFetch(
            `${REACT_APP_API_URL}/Claims/PostNCD`,
            {debtorid,nextcontactdate:e.target.value},
            'POST',
            {},
            instance,
            accounts
        )
        .then(json=>{
            // Whenever a user changes NCD it adds an activity, so we have to call
            // this function to update the activities and the NCD rooted in the
            // CollectorClaimModal
            updateAccountFieldsAndActivities(json)
            setLoading(false)
            setStatus(200)
        })
        .catch(err=>{
            setLoading(false)
            setError('There was an issue, please try again.')
        })
        onChange(e)
     }
  return (
    <>
        <Form.Control 
            disabled={disabled} 
            type='date'
            value={date?date.split('T')[0]:''} 
            onChange={(e)=>setDate(e.target.value)}
            onBlur={(e)=>handleBlur(e)} 
        />
        {loading?
        <Spinner animation='border' size='sm'/>
        :error?
        <BsX />
        :(status===200)?
        <BsCheck />
        :null}
    </>
  )
}

export default NCDDropdown