import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import ReactSelect from "react-select";
import { useMsal } from "@azure/msal-react";
import FuncFetch from "../../Utilities/funcFetch";
import { Spinner } from "react-bootstrap";

function AdvancedSearchRowSelector({
  fieldOptions,
  newRule,
  setNewRule,
  allRules,
  setAllRules,
  index
}) {
  const numberOptions = [
    {
      value: "Equal",
      label: "Equal",
    },
    {
      value: "NotEqual",
      label: "Not Equal",
    },
    {
      value: "GreaterThan",
      label: "Greater Than",
    },
    {
      value: "LessThan",
      label: "Less Than",
    },
    {
      value: "GreaterThanOrEqual",
      label: "Greater Than Or Equal",
    },
    {
      value: "LessThanOrEqual",
      label: "Less Than Or Equal",
    },
  ];
  const dateOptions = [
    {
      value: "Equal",
      label: "Equal",
    },
    {
      value: "GreaterThan",
      label: "Greater Than",
    },
    {
      value: "LessThan",
      label: "Less Than",
    },
  ];
  const booleanOptions = [
    {
      value: "Equal",
      label: "Equal",
    },
  ];
  const stringOptions = [
    {
      value: "Equal",
      label: "Equal",
    },
    {
      value: "Contains",
      label: "Contains",
    },
    {
      value: "NotContains",
      label: "NotContains",
    },
  ];
  const [dynamicDropdownValue, setDynamicDropdownValue] = useState();
  const [dynamicValueOptions, setDynamicValueOptions] = useState();
  const { instance, accounts } = useMsal();
  const { REACT_APP_API_URL } = process.env;
  const [readyToLoad, setReadyToLoad] = useState(false);

  useEffect(() => {
    // If this is rendered as a blank rule, there will not be a serNewRule function passed
    // we also have to wait for fieldOptions to be populated
    // once we have gone through this process, the searchField will be an object, 
    // so this is to esnsure we are not running after it has been set to an object
      if ( setAllRules && fieldOptions && newRule ) {
        if((typeof newRule.searchField === 'string')){
          let fieldOptionWithAllValues = fieldOptions.find(
            // I am going to check if the searchField is an object or a string
            (field) => field.value.field === newRule.searchField
          );
          newRule.searchField = fieldOptionWithAllValues.value;
          // If the searchField has a lookup, we need to grab the values from the API
          if (newRule.searchField?.lookup !== "None") {
            getAndSetDynamicValue(fieldOptionWithAllValues);
          // else we just load the row
          }else{
            setReadyToLoad(true);
          }
        //if the newRule.searchField is an object, we need to check if it has a lookup
        // and either grab the values from the API or set the row to load
        }else if(newRule.searchField?.lookup !== "None"){
          getAndSetDynamicValue({value:newRule.searchField});
        }else{
          setReadyToLoad(true);
        }
      // if this is the first render and newRule is blank, we need to set the row to load so they can fill out a rule
      } else if ((setNewRule && fieldOptions) || ((typeof newRule.searchField === 'object') && fieldOptions)) {
        setReadyToLoad(true);
      }
// eslint-disable-next-line
  }, [fieldOptions, newRule, setNewRule]);


  // When user selects a new Field we dynamically grab the Operators and Values
  const handleChange = (e, fieldName) => {
    let newRuleObj = {};
    if(newRule){
      newRuleObj = { ...newRule };
    }else if(allRules){
      newRuleObj = { ...allRules[index] };
    }

    if (fieldName === "searchValue") {
      newRuleObj.searchValue = e.target.value;
    } else if (fieldName === "selectSearchValue") {
      newRuleObj.searchValue = e.value;
    } else if (fieldName === "searchField") {
      setDynamicDropdownValue(null);
      newRuleObj = {
        searchField: e.value,
        searchCondition: "AND",
      };
      if (e.value.lookup !== "None") {
        //If there is a lookup listed we can grab the Values from the API by passing the lookup string to the URL
        getAndSetDynamicValue(e);
      }
    } else if (fieldName === "searchValueSelect") {
      setDynamicDropdownValue(e);
      newRuleObj.searchValue = e.value;
    } else {
      newRuleObj[fieldName] = e.value;
    }
    if (setNewRule) {
      // if this is the blank rule, we use set state
      // if it is an existing rule being edited, we do not
      setNewRule(newRuleObj);
    }else if(setAllRules){
      let newAllRules = [...allRules];
      newAllRules[index] = newRuleObj;
      setAllRules(newAllRules);
    }
  };

  const getAndSetDynamicValue = (e) => {
    let apiEndpoint = e.value.lookup.split("(")[0];
    FuncFetch(
      `${REACT_APP_API_URL}/Claims/${apiEndpoint}`,
      null,
      "GET",
      null,
      instance,
      accounts
    ).then((response) => {
        let options = response.map((obj) => {
            return { value: obj.key, label: obj.value };
          })
          setDynamicValueOptions(options);
      if(!setNewRule){
        setDynamicDropdownValue(options.find((option) => option.value.toLowerCase() === newRule.searchValue.toLowerCase()));
        setReadyToLoad(true);
      }
    });
  };

  return readyToLoad ? (
    <>
      <Col>
        <Form.Label>Field</Form.Label>
        <ReactSelect
          options={fieldOptions}
          value={fieldOptions.find(
            (field) => field.value.field === newRule?.searchField?.field
          )}
          onChange={(e) => handleChange(e, "searchField")}
        />
      </Col>
      <Col>
        <Form.Label>Operator</Form.Label>
        <ReactSelect
          options={
            newRule.searchField?.type === "Number"
              ? numberOptions
              : newRule.searchField?.type === "Date"
              ? dateOptions
              : newRule.searchField?.type === "Boolean"
              ? booleanOptions
              : newRule.searchField?.type === "String"
              ? stringOptions
              : null
          }
          isDisabled={newRule.searchField ? false : true}
          value={
            newRule.searchOperator
              ? {
                  value: newRule.searchOperator,
                  label: newRule.searchOperator,
                }
              : null
          }
          onChange={(e) => handleChange(e, "searchOperator")}
        />
      </Col>
      <Col>
        <Form.Label>Value</Form.Label>
        {newRule.searchField?.lookup !== "None" ? (
          <ReactSelect
          className="dynamic-value-select"
            options={dynamicValueOptions}
            value={dynamicDropdownValue}
            onChange={(e) => handleChange(e, "searchValueSelect")}
          />
        ) : newRule.searchField?.type === "Number" ? (
          <Form.Control
          className="value-type-number"
            type="number"
            defaultValue={newRule.searchValue ? newRule.searchValue : ""}
            onBlur={(e) => handleChange(e, "searchValue")}
          />
        ) : newRule.searchField?.type === "Date" ? (
          <Form.Control
            type="date"
            defaultValue={newRule.searchValue ? newRule.searchValue : ""}
            onBlur={(e) => handleChange(e, "searchValue")}
          />
        ) : newRule.searchField?.type === "Boolean" ? (
          <ReactSelect
            options={[
              { value: "True", label: "True" },
              { value: "False", label: "False" },
            ]}
            value={{value:newRule.searchValue,label:newRule.searchValue}}
            onChange={(e) => handleChange(e, "selectSearchValue")}
          />
        ) : newRule.searchField?.type === "String" ? (
          <Form.Control
            defaultValue={newRule.searchValue ? newRule.searchValue : ""}
            onBlur={(e) => handleChange(e, "searchValue")}
          />
        ) : (
          <Form.Control disabled={true} />
        )}
      </Col>
    </>
  ) : (
    <Spinner animation="border" />
  );
}

export default AdvancedSearchRowSelector;
