import useFetch from "../../Utilities/useFetch";
import { useEffect } from "react";
import React, { useState } from "react";
import Col from "react-bootstrap/Col";
import FuncFetch from "../../Utilities/funcFetch";
import { useMsal } from "@azure/msal-react";
import "./SearchForm.css";
import SaveSearchModal from "./SaveSearchModal";
import AdvancedSearchRowSelector from "./AdvancedSearchRowSelector";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import Spinner from "react-bootstrap/Spinner";
import Row from "react-bootstrap/Row";
import { Form } from "react-bootstrap";

export const AdvancedSearch = ({ setClaims }) => {
  const [fieldOptions, setFieldOptions] = useState();
  const [saveSearchModalIsOpen, setSaveSearchModalIsOpen] = useState(false);
  const [postObj, setPostObj] = useState([]);
  const [newRule, setNewRule] = useState({
    searchCondition: "AND",
  });
  const { instance, accounts } = useMsal();
  const { REACT_APP_API_URL } = process.env;
  const [searchLoading, setSearchLoading] = useState(false);
  const { data: searchFields } = useFetch(
    true,
    `${REACT_APP_API_URL}/Claims/GetSearchFields`
  );
  const { data: savedSearches, loading: loadingSavedSearches } = useFetch(
    true,
    `${REACT_APP_API_URL}/Claims/GetSavedSearches`
  );

  useEffect(() => {
    if (searchFields) {
      let searchFieldsAsOptions = searchFields.map((fieldObj) => {
        let option = { value: fieldObj, label: fieldObj.field };
        return option;
      });
      setFieldOptions(searchFieldsAsOptions);
    }
  }, [searchFields]);

  const handleAddRule = () => {
    newRule.searchField = newRule.searchField.field;
    setPostObj([...postObj, newRule]);
    let blankNewRule = {
      searchCondition: "AND",
    };
    setNewRule(blankNewRule);
    document.getElementById("advanced-search-rule-create-form").reset();
  };

  const populateSavedSearch = (base64Search) => {
    setPostObj(JSON.parse(window.atob(base64Search)));
  };

  const removeRule = (index, id) => {
    let newPostObj = [...postObj];
    newPostObj = newPostObj
      .slice(0, index)
      .concat(newPostObj.slice(index + 1, newPostObj.length));
    // document.getElementById(id).remove()
    setPostObj(newPostObj);
  };

  const submitRulesToSearch = () => {
    setSearchLoading(true);
    let searchItems=[];
    postObj.forEach((obj) => {
      searchItems.push({
        ...obj,
        searchField: obj.searchField.field,
      })
    });
    FuncFetch(
      `${REACT_APP_API_URL}/Claims/AdvancedSearch`,
      null,
      "POST",
      { searchItems },
      instance,
      accounts
    )
      .then((response) => {
        setSearchLoading(false);
        setClaims(response);
      })
      .catch((error) => {
        console.log(error);
        setSearchLoading(false);
      });
  };

  return (
    <>
      {saveSearchModalIsOpen ? (
        <SaveSearchModal
          searchParams={postObj}
          show={saveSearchModalIsOpen}
          setShow={setSaveSearchModalIsOpen}
        />
      ) : null}
      <Form id="advanced-search-rule-create-form">
        <Row className="advanced-search-rules-selector">
          <AdvancedSearchRowSelector
            fieldOptions={fieldOptions}
            newRule={newRule}
            setNewRule={setNewRule}
          />
          <Col className="advanced-search-button-wrapper">
            <ButtonGroup>
              <Button onClick={() => handleAddRule()} className="full-width">
                Add rule
              </Button>
              <DropdownButton
                id="saved-search-dropdown"
                variant="outline-primary"
                as={ButtonGroup}
                title="Saved Searches"
                // I clear the postObj so that when a new search is selected, the old search is not appended to the new search
                onClick={(e) => {
                  if (e.target.id === "saved-search-dropdown") {
                    setPostObj([]);
                  }
                }}
              >
                {savedSearches ? (
                  savedSearches.map((search, index) => {
                    return (
                      <Dropdown.Item
                        onClick={() => populateSavedSearch(search.searchJSON)}
                        key={`${search.searchName}${index}`}
                      >
                        {search.searchName}
                      </Dropdown.Item>
                    );
                  })
                ) : loadingSavedSearches ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  <Dropdown.Item>No saved searches</Dropdown.Item>
                )}
              </DropdownButton>
            </ButtonGroup>
          </Col>
        </Row>
      </Form>
      {postObj.map((obj, index) => {
        let rowId = obj.searchField.field + obj.searchOperator + obj.searchValue;
        return (
          <Row
            id={rowId}
            key={rowId}
            className="advanced-search-rule-spacing"
          >
            <AdvancedSearchRowSelector
              fieldOptions={fieldOptions}
              allRules={postObj}
              setAllRules={setPostObj}
              index={index}
              newRule={obj}
            />
            <Col>
              <Button
                onClick={() =>
                  removeRule(
                    index,
                    rowId
                  )
                }
                variant="danger"
              >
                Remove Rule
              </Button>
            </Col>
          </Row>
        );
      })}
      {postObj.length > 0 ? (
        <ButtonGroup className="margin-top">
          <Button onClick={() => submitRulesToSearch()}>
            {searchLoading ? (
              <Spinner animation="border" size="sm" />
            ) : (
              "Search"
            )}
          </Button>
          <Button
            variant="outline-primary"
            onClick={() => setSaveSearchModalIsOpen(true)}
          >
            Save Search
          </Button>
        </ButtonGroup>
      ) : null}
    </>
  );
};
