import React from 'react'
import './ClaimLookup.css'
import ErrorBoundary from '../Auth/ErrorBoundary';

const FormRow = ({children, variant, className}) => {
    return (
        <ErrorBoundary>
            <div className={`form-row ${variant==='sm'?'form-row-small':''} ${className?className:''}`}>
                {children}
            </div>
        </ErrorBoundary>
    );
}
 
export default FormRow;