import React, { useState } from "react";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import Col from "react-bootstrap/Col";
import FuncFetch from "../../Utilities/funcFetch";
import { useMsal } from "@azure/msal-react";

function SaveSearchModal({ show, setShow, searchParams }) {
  const { REACT_APP_API_URL } = process.env;
  const { instance, accounts } = useMsal();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [status, setStatus] = useState();
  const [searchName, setSearchName] = useState();
  const [isPublic, setIsPublic] = useState(false);

  const handleSaveSearch = () => {
    setError(null);
    setStatus(null);
    setLoading(true);
    if (!searchName || searchName === "") {
      setError("Search Name is required");
      setLoading(false);
    }
    let searchItems=[];
    searchParams.forEach((obj) => {
      searchItems.push({
        ...obj,
        searchField: obj.searchField.field,
      })
    });
    FuncFetch(
      `${REACT_APP_API_URL}/Claims/PostSaveSearch`,
      null,
      "POST",
      {
        searchName,
        searchJSON: window.btoa(JSON.stringify(searchItems)),
        createdUserName: accounts[0].name,
        isPublic,
      },
      instance,
      accounts
    )
      .then((response) => {
        setLoading(false);
        setStatus(200);
      })
      .catch((error) => {
        setLoading(false);
        setError("There was an issue, please try again" + error.text);
      });
  };

  return (
    <Modal show={show} onHide={setShow} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Create New Saved Search</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Label>
          Search Name:<span>*</span>
        </Form.Label>
        <Form.Control
          value={searchName}
          onChange={(e) => setSearchName(e.target.value)}
        />

        <Form.Label>Public:</Form.Label>
        <Form.Check
          type="switch"
          checked={isPublic}
          onChange={(e) => setIsPublic(e.target.checked)}
        ></Form.Check>

        <Form.Label className="margin-top">Search Criteria:</Form.Label>
        {searchParams.map((obj) => {
          return (
            <Row className="advanced-search-rule-spacing">
              <Col>
                <Form.Control disabled value={obj.searchField.field} />
              </Col>
              <Col>
                <Form.Control disabled value={obj.searchOperator} />
              </Col>
              <Col>
                <Form.Control disabled value={obj.searchValue} />
              </Col>
              <Col>
                <Form.Control disabled value={obj.searchCondition} />
              </Col>
            </Row>
          );
        })}
      </Modal.Body>
      <Modal.Footer>
        <Row className="full-width">
          <Col>
            <Button
              className="full-width"
              variant="secondary"
              onClick={() => setShow(false)}
            >
              Close
            </Button>
          </Col>
          <Col>
            <Button
              className="full-width"
              variant="primary"
              onClick={() => handleSaveSearch()}
            >
              {loading ? <Spinner animation="border" /> : "Save Changes"}
            </Button>
          </Col>
        </Row>
        {error ? (
          <Alert className="full-width" variant="warning">
            {error}
          </Alert>
        ) : null}

        {status === 200 ? (
          <Alert className="full-width">Successfully saved search!</Alert>
        ) : null}
      </Modal.Footer>
    </Modal>
  );
}

export default SaveSearchModal;
