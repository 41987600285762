import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import useFetch from '../../../Utilities/useFetch';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function AddPhonePopup({ debtorID, show, setShow, setPhones }) {
  const [phone, setPhone] = useState();
  const [isRO, setIsRO] = useState(false);
  const [isClaimant, setIsClaimant] = useState(true);
  const [error, setError] = useState();
  const [paramsObj, setParamsObj] = useState();
  const [postObj] = useState({})
  const { REACT_APP_API_URL } = process.env;
  const { data: newPhoneList, status, loading, error: addPhoneError } = useFetch(paramsObj,`${REACT_APP_API_URL}/Claims/AddPhoneNumber`,'POST', postObj, paramsObj);

  useEffect(()=>{
    if(status){
      setParamsObj(false);
      if(addPhoneError){
        setError('There was an error, please try again')
      }
    }
    //eslint-disable-next-line
  },[status])

  useEffect(()=>{
    if(newPhoneList&&status===200){
      setPhones(newPhoneList)
    }
    //eslint-disable-next-line
  },[newPhoneList])

  const postNewPhone = (e) => { 
    e.preventDefault();
    if(isValid(phone)){
      setParamsObj({
        DebtorID:debtorID,
        PhoneNumber:phone,
        isClaimant,
        isRO
      })
    }else{
      setError("Phone number is invalid")
    }
   }

  const handlePhoneUpdate = (newNumber) => { 
    setError(null)
    setPhone(newNumber)
   }
  const isValid = (phone) => {
    //eslint-disable-next-line
    var phoneRe = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    return phoneRe.test(phone);
  }

  return (
    <Modal show={show} onHide={setShow}>
      <Form onSubmit={postNewPhone}>
      <Modal.Header closeButton>
        <h4>Add New Phone</h4>
      </Modal.Header>
      <Modal.Body>
          <Form.Group>
              <Form.Label>Phone Number:</Form.Label>
              <Form.Control 
                value={phone} 
                onChange={(e) => handlePhoneUpdate(e.target.value)} 
              />
          </Form.Group>
          <Row>
            <Col>
              <Form.Group>
                  <Form.Check 
                    reverse 
                    label='Claimant' 
                    checked={isClaimant} 
                    onChange={(e) => setIsClaimant(e.target.checked)} 
                  />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                  <Form.Check 
                    reverse 
                    label='RO'
                    checked={isRO} 
                    onChange={(e) => setIsRO(e.target.checked)} 
                  />
              </Form.Group>
            </Col>
            <Col></Col>
            <Col></Col>
          </Row>
          {error&&
            <Alert variant='warning'>{error}</Alert>
          }
      </Modal.Body>
      <Modal.Footer>
        {(status===200)?
          <Alert className='full-width'>Successfully added new phone number</Alert>
          :
          <Button className='full-width' type='submit'>{loading?<Spinner animation='border' size='sm'/>:'Submit'}</Button>
        }
        <Button className='full-width' variant='secondary' onClick={()=>setShow(false)}>Close</Button>
      </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default AddPhonePopup