import React, { useState } from 'react'
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import { Button } from 'react-bootstrap';
import FuncFetch from '../../Utilities/funcFetch';
import { useMsal } from '@azure/msal-react';
import AssignClaimPopup from './AssignClaimPopup';

function TakeClaimAssignment(props) {
  let debtorId, collector, updateAccountFieldsAndActivities;
  // This chunk of code is here because I use this component in two different ways
  // one way is a cellRenderer in AgGrid, the other is a standalone component
  // AgGrid is the one that passes the row data, hence pulling the necessary data from the props
  // The updateAccountFieldsAndActivities function is passed in as a prop when the component is used in the CollectorClaims component
  if(props.data){
    const { debtorId: extractedDebtorId, collector: extractedCollector } = props.data;
    debtorId = extractedDebtorId;
    collector = extractedCollector;
  }else{
    const {debtorId: extractedDebtorId, updateAccountFieldsAndActivities: exctractedUpdateNCDAndActivities} = props;
    debtorId = extractedDebtorId;
    updateAccountFieldsAndActivities = exctractedUpdateNCDAndActivities;
  }
  const {instance,accounts} = useMsal()
  const { REACT_APP_API_URL } = process.env;
  const [message, setMessage] = useState()
  const [error,setError] = useState()
  const [loading, setLoading] = useState(false);
  const [assignClaimPopupIsOpen, setAssignClaimPopupIsOpen] = useState(false);

  const handleAssignButtonClicked = () => { 
    setLoading(true)
    FuncFetch(`${REACT_APP_API_URL}/Claims/IsCollectorAdmin`,null,'GET',null,instance,accounts)
    .then(response=>{
      if(response===true){
        setLoading(false)
        setAssignClaimPopupIsOpen(true)
        return;
      }else if(response===false){
        handleSubmit()
      }
    })
    .catch(err=>{
      setLoading(false)
      setError(err)
      console.error('There was an issue, please try again.')
    })
   }

  const handleSubmit = () => { 
      FuncFetch(`${REACT_APP_API_URL}/Claims/TakeClaimAssignment`,{debtorId},'POST',null,instance,accounts)
      .then(response=>{
        // If the parent component is, or is nested in, the CollectorClaims component we need to 
        // update the activities
        if(updateAccountFieldsAndActivities){
          updateAccountFieldsAndActivities(response)
        }
        setMessage(response.message)
        setLoading(false)
      })
      .catch(err=>{
        setLoading(false)
        setError(err)
        console.error('There was an issue, please try again.')
      })
  }

  return (
    <>
    {assignClaimPopupIsOpen?<AssignClaimPopup debtorID={debtorId} collector={collector} show={assignClaimPopupIsOpen} setShow={setAssignClaimPopupIsOpen} />:null}
    {message?
      <Alert>{message}</Alert>
    :
      <Button onClick={()=>handleAssignButtonClicked()} className='take-claim-assignment-button'>
        {loading?
        <Spinner animation='border' size='sm' />
        :'Assign Claim'}
      </Button>
    }
    {error?
      <Alert variant='warning'>Error</Alert>
    :null}
    </>
  )
}

export default TakeClaimAssignment