import React from 'react'
import './ClaimLookup.css'
import ErrorBoundary from '../Auth/ErrorBoundary';

const FormCol = ({children, variant, className}) => {
    return (
        <ErrorBoundary>
            <div className={`form-col ${variant==='sm'?'form-col-small':''} ${className?className:''}`}>
                {children}
            </div>
        </ErrorBoundary>
    );
}
 
export default FormCol;