import React, { useContext, useEffect, useState } from 'react';
import { Modal, Button, Form, Spinner, Alert } from 'react-bootstrap';
import Select from 'react-select';
import useFetch from '../Utilities/useFetch';
import FuncFetch from '../Utilities/funcFetch';
import { useMsal } from '@azure/msal-react';
import ErrorBoundary from '../Auth/ErrorBoundary';
import CustomerContext from '../Auth/CustomerContext';

const AddClaimPopup = ({ show, handleClose }) => {
    const [claimNumber, setClaimNumber] = useState();
    const [selectedClient, setSelectedClient] = useState(null);
    const [clientOptions, setClientOptions] = useState(null);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(null);
    const { data: clients } = useFetch(true, `${process.env.REACT_APP_API_URL}/Claims/GetClients`, 'GET');
    const { instance, accounts } = useMsal();
    const { claimNumber: globalClaimNumber, setClaimNumber: setGlobalClaimNumber } = useContext(CustomerContext);

    useEffect(() => {
        if(globalClaimNumber){
            setClaimNumber(globalClaimNumber)
        }
    // eslint-disable-next-line
    },[globalClaimNumber])

    useEffect(() => {
        if (clients) {
            setClientOptions(clients.map((client) => ({
                value: client.key,
                label: client.value,
            })));
        }
    }, [clients]);

    const handleSubmit = (e) => {
        setLoading(true);
        setErrors(null);
        e.preventDefault();
        let postObj = {
            ClientRefno: claimNumber,
            clientid: selectedClient.value,
        };
        FuncFetch(`${process.env.REACT_APP_API_URL}/Claims/GenerateDebtorID`, postObj, 'POST', null, instance, accounts)
        .then((response) => {
            // send the user to the new claim page
            setGlobalClaimNumber(response);
            setLoading(false);
        })
        .catch((error) => {
            console.error('Error:', error);
            setLoading(false);
            setErrors('There has been an issue, please try again');
        });
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Add Claim</Modal.Title>
            </Modal.Header>
            <ErrorBoundary>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group controlId="formClaimNumber">
                            <Form.Label>Claim Number<span className='required-field'>*</span></Form.Label>
                            <Form.Control
                                type="text"
                                value={claimNumber}
                                onChange={(e) => setClaimNumber(e.target.value)}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="formClientSelect">
                            <Form.Label>Select Client<span className='required-field'>*</span> </Form.Label>
                            <Select
                                options={clientOptions}
                                value={selectedClient}
                                onChange={setSelectedClient}
                                required
                            />
                        </Form.Group>
                        <Button variant="primary" type="submit" className='margin-top'>
                            {loading?
                            <Spinner animation="border" size='sm'/>
                            :'Submit'}
                            {errors&&<Alert variant='warning'>{errors}</Alert>}
                        </Button>
                    </Form>
                </Modal.Body>
            </ErrorBoundary>

        </Modal>
    );
};

export default AddClaimPopup;