import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { EventType, PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './authConfig';

// Bootstrap components
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter } from 'react-router-dom';
import { CustomerProvider } from './Components/Auth/CustomerContext';

//AgGrid components
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.enableAccountStorageEvents()

const root = ReactDOM.createRoot(document.getElementById('root'));

msalInstance.addEventCallback((event) => {
  if(event.eventType === EventType.LOGIN_SUCCESS && event.payload.account){
    const account = event.payload.account
    msalInstance.setActiveAccount(account)
    window.location.reload()
  }
})
msalInstance.initialize().then(()=>{
  root.render(
    <MsalProvider instance={msalInstance}>
      <CustomerProvider>
        <BrowserRouter>
            <App />
        </BrowserRouter>
      </CustomerProvider>
    </MsalProvider>
  );
})