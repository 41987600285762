import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import { Button } from "react-bootstrap";

/**
 * Renders a drop down button with child buttons for logging in with a popup or redirect
 * Note the [useMsal] package 
 */

export const SignInButton = () => {
  const { instance } = useMsal();

  const handleLogin = (loginType) => {
    if (loginType === "popup") {
      instance.loginPopup(loginRequest).catch((e) => {
        console.error(e);
      });
    } else if (loginType === "redirect") {
      instance.loginRedirect(loginRequest)
      .then(response=>{
          // User has logged in successfully
          const account = response.account;
          instance.setActiveAccount(account);
      })
      .catch((e) => {
        console.error(e);
      });
    }
  };
  return (
      <Button as="button" variant="light" onClick={() => handleLogin("redirect")}>
        Sign in
      </Button>
  );
};