import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useContext, useEffect } from "react";
import CustomerContext from "./CustomerContext";
import FuncFetch from '../Utilities/funcFetch'
import { PageLayout } from "../Layout/PageLayout";
import { loginRequest } from "../../authConfig";

function ProtectedRoute({children}) {
  const isAuthenticated = useIsAuthenticated();
  const { cookies } = useContext(CustomerContext)
  const { REACT_APP_API_URL } = process.env;
  const { instance, accounts, inProgress } = useMsal();

  useEffect(() => {
    let activeAccount = instance.getActiveAccount()
    if(isAuthenticated===false){
        console.info('NOT AUTHENTICATED')
        if(inProgress !== "none"){
            console.info('IN PROGRESS, RETURNING')
            return;
        }else if(!activeAccount&&accounts.length>0){
            console.info('NO ACTIVE ACCOUNT, SETTING ACTIVE ACCOUNT')
            activeAccount = accounts[0]
            instance.setActiveAccount(accounts[0])
            window.location.reload()
        }else if(!activeAccount&&accounts.length===0){
            console.info('NO ACCOUNTS FOUND')
            instance.loginRedirect(loginRequest)
        }
    }
    // eslint-disable-next-line
  }, [isAuthenticated,inProgress,accounts])

  useEffect(() => {
    if(!cookies.get('customer')&&isAuthenticated){
      FuncFetch(`${REACT_APP_API_URL}/Authentication/Login`,null,'GET',null,instance,accounts)
      .then(data=>{
        cookies.set('customer',data)
      })
      .catch(err=>{
        console.error(err)
      })
    }
    // eslint-disable-next-line
  },[])

  return (isAuthenticated ? <PageLayout>{children}</PageLayout> : <></>)
}

export default ProtectedRoute