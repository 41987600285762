import React, { useState } from 'react'
import Cookies from 'universal-cookie'

export const CustomerContext = React.createContext();

export function CustomerProvider({ children }) {

    const cookies = new Cookies();

    const [claimNumber, setClaimNumber] = useState();

  return (
    <CustomerContext.Provider value={{
        cookies,
        claimNumber,
        setClaimNumber
    }}>
        {children}
    </CustomerContext.Provider>
  )
}

export default CustomerContext