import React, { useState, useEffect, useContext } from 'react'
import Navbar from 'react-bootstrap/Navbar'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import { useIsAuthenticated } from "@azure/msal-react";
import { SignInButton } from "../SignInButton";
import { SignOutButton } from "../SignOutButton";
import './Header.css'
import { useMsal } from '@azure/msal-react';
import { Row, Col, NavDropdown } from 'react-bootstrap';
import CustomerContext from '../Auth/CustomerContext';
import AddClaimPopup from '../Claims/AddClaimPopup';
import CollectorClaimModal from '../Claims/NCD/CollectorClaimModal';
import useFetch from '../Utilities/useFetch';
import { useNavigate } from 'react-router-dom';

function Header() {
    const isAuthenticated = useIsAuthenticated();
    const { instance } = useMsal();
    const { claimNumber, setClaimNumber} = useContext(CustomerContext)
    const [newClaimFormIsOpen, setNewClaimFormIsOpen] = useState(false)
    const [claimFormIsOpen, setClaimFormIsOpen] = useState(false)
    const {data: isClaimCreator} = useFetch(true,`${process.env.REACT_APP_API_URL}/Claims/isClaimCreator`,'GET')
    const params = new URLSearchParams(window.location.search);
    const paramClaimNumber = params.get('claimNumber')
    const navigate = useNavigate();
    let activeAccount = instance.getActiveAccount()

    useEffect(()=>{
        if(paramClaimNumber){
            setClaimNumber(paramClaimNumber)
        }
    // eslint-disable-next-line
    },[params])

    useEffect(()=>{
        if(claimNumber){
            setClaimFormIsOpen(true)
        }
    // eslint-disable-next-line
    },[claimNumber])
    
    const handleClaimModalClose = () => { 
        setClaimNumber(null)
        setClaimFormIsOpen(false)
        navigate('/');
     }

  return (
    <>
    {newClaimFormIsOpen&&<AddClaimPopup show={newClaimFormIsOpen} handleClose={()=>setNewClaimFormIsOpen(false)} />}
    {claimFormIsOpen&&<CollectorClaimModal show={claimFormIsOpen} setShow={handleClaimModalClose} selectedClaim={claimNumber} setSelectedClaim={setClaimNumber}/>}
    <Navbar bg="dark" variant='dark'>
        <Container>
            <Navbar.Brand href="/">RMS Staff</Navbar.Brand>

            <Navbar.Toggle aria-controls="basic-navbar-nav" />

            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className={isAuthenticated?"navigation-wrapper":"logged-out-navigation-wrapper"}>
                    {isAuthenticated?
                    <>
                        <NavDropdown title="Claims">
                            <NavDropdown.Item href="/Claims/OpenClaims">Claims Search</NavDropdown.Item>
                            <NavDropdown.Item href="/Claims/NCD">NCD</NavDropdown.Item>
                            {isClaimCreator&&
                            <NavDropdown.Item onClick={()=>setNewClaimFormIsOpen(true)}>Add New</NavDropdown.Item>
                            }
                        </NavDropdown>
                        <Row>
                            <Col className='login-menu-item'><SignOutButton /></Col>
                            {activeAccount&&
                            <Col className='logged-in-user-name'>
                                <p>{activeAccount.name}</p>
                            </Col>
                            }
                        </Row>
                    </>
                    :
                    <div>
                        <SignInButton />
                    </div>
                    }
            
                </Nav>
            </Navbar.Collapse>
        </Container>
    </Navbar>
    </>
  )
}

export default Header