import React, { useState } from 'react'
import Form from 'react-bootstrap/Form'
import FuncFetch from '../Utilities/funcFetch'
import { useMsal } from '@azure/msal-react'
import Spinner from 'react-bootstrap/Spinner'
import { BsCheck, BsX } from 'react-icons/bs'

function SkipTraceToggleCheckbox({
    readOnly,
    defaultChecked,
    debtorid,
    updateAccountFieldsAndActivities
}) {
    const [checked, setChecked] = useState(defaultChecked)
    const [loading, setLoading] = useState(false)
    const [status, setStatus] = useState()
    const [error, setError] = useState()
    const {instance,accounts} = useMsal();

    const handleToggleChecked = () => {
        setLoading(true)
        setStatus(null)
        setError(null)
        FuncFetch(
            `${process.env.REACT_APP_API_URL}/Claims/ToggleSkipTrace`,
            {debtor:debtorid},
            'POST',
            null,
            instance,
            accounts
        )
        .then(json=>{
            updateAccountFieldsAndActivities(json)
            setChecked(!checked)
            setLoading(false)
            setStatus(200)
        })
        .catch(err=>{
            setLoading(false)
            setStatus(400)
            setError('There was an issue, please try again.')
        })
    }
  return (
    <>
    <Form.Check readOnly={readOnly} checked={checked} onChange={handleToggleChecked}/>
    {loading?
        <Spinner animation='border' size='sm'/>
    :error?
        <BsX />
    :(status===200)?
        <BsCheck />
    :null}
    </>
  )
}

export default SkipTraceToggleCheckbox